<template>
  <div class="content md flex-box vertical">
    <data-list
      class="flex-grow"
      ref="dataList"
      :url="url"
      query="&sort=sort,-id&expand=groupLeader,groupCount,interviewCount,practiceCount,formalCount,hibernateCount"
      entity-name="小组"
      show-row-num
      hide-show-detail
      hide-edit
      hide-delete
      add-btn="添加小组"
      :columns="columns"
      :search-key-type="searchKeyType"
      :add-route="addRoute"
      :deal-list="dealList"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="输入小组名称查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择小组类型查询"
            @change="updateList"
            :options="groupOptions"
            v-model="form.classify"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <role-select
            v-model="form.group_leader"
            :privilege="9"
            :props="{ placeholder: '请选择组长', allowClear: true, style: { width: '180px' } }"
            @change="updateList"
          ></role-select>
        </a-form-model-item>
      </template>
      <template v-slot:action="{ item }">
        <div class="row-btn" @mousedown.stop @mouseup.stop>
          <a class="txt-btn" @click.stop="showDetail(item)" v-action:query>查看</a>
          <a class="txt-btn" @click.stop="editRow(item)" v-action:update>编辑</a>
          <a class="txt-btn danger" @click.stop="delRow(item)" v-action:delete>删除</a>
        </div>
      </template>
    </data-list>
  </div>
</template>

<script>
import { getKeyTitle } from "../../../common/js/tool";
import { groupOptions } from "../../../common/hr/volunteer";

export default {
  name: "GroupList",
  data() {
    return {
      url: "/admin/volunteer-group",
      searchKeyType: {
        name: 1,
      },
      columns: [
        { title: "小组名称", dataIndex: "name" },
        {
          title: "小组分类",
          dataIndex: "classify",
          customRender: (text) => {
            return <span>{getKeyTitle(groupOptions, text, "value", "label")}</span>;
          },
        },
        {
          title: "组长姓名",
          dataIndex: "group_leader",
          customRender: (text) => {
            return <open-data type="userName" openid={text} />;
          },
        },
        { title: "小组总人数", dataIndex: "groupCount" },
        { title: "待面试人数", dataIndex: "interviewCount" },
        { title: "实习期人数", dataIndex: "practiceCount" },
        { title: "正式人数", dataIndex: "formalCount" },
        { title: "休眠人数", dataIndex: "hibernateCount" },
        { title: "排序", dataIndex: "sort" },
      ],
      groupOptions,
      addRoute: "GroupAdd",
      groupLeaderOptions: [],
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  methods: {
    getGroupLeader() {
      this.$axios({
        url: "/admin/user-role",
        method: "GET",
        params: {
          sort: "-id",
          "filter[system]": 2,
          "filter[privilege]": 9, // 组长
        },
      }).then((res) => {
        this.groupLeaderOptions = [];
        res.data?.forEach((item) => {
          if (item.user_id) {
            item.user_id = JSON.parse(item.user_id);
            item.user_id?.forEach((id) => {
              this.groupLeaderOptions.push({ ...item, value: id });
            });
          }
        });
      });
    },
    dealList(list, fn) {
      list?.forEach((item) => {
        let status = 1;
        item.status = status;
      });
      fn(list);
    },
    updateList() {
      this.dataList.updateList();
    },
    showDetail(item) {
      this.$router.push({
        name: "GroupDetail",
        params: { id: item.id },
      });
    },
    editRow(item) {
      this.$router.push({
        name: "GroupDetail",
        params: { id: item.id },
        query: { edit: 1 },
      });
    },
    delRow(item) {
      this.$confirm({
        title: "提示",
        content: `确定删除此志工小组吗？`,
        onOk: () => {
          this.$axios({
            url: `${this.url}/${item.id}`,
            method: "DELETE",
          }).then(() => {
            this.$message.success("删除成功");
            this.updateList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
